<template>
    <div class="PersonalCenter">

        <div class="head f-c-b">
            <div class="LeftPart f-c">
                <img class="logo" src="@/assets/Community/logo.png" alt="">
                <img class="shiliaologo" src="@/assets/Community/shiliaologo.png" alt="">
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main f-cl-c-c">
            <div class="title flex">
                <div :class="select == 1 ? 'select' : 'Noselect'" @click="select = 1" style="margin-right: 50px;">个人简历列表
                </div>
                <div :class="select == 2 ? 'select' : 'Noselect'" @click="select = 2">已投递职位职位</div>
            </div>
            <div class="list f-cl-c" v-if="select == 1">
                <div v-if="resumelist" class="resumelist f-cl-c">
                    <div v-for="(item, index) in resumelist" :key="index" class="resumeItem f-c-b" @click="toresumeDetails(item.resumeId)">
                        <div class="infoBox">
                            <div class="flex">
                                <div>
                                    <span class="name">{{ item.name }}</span>
                                    <span class="contactValue"> · </span>
                                    <span class="contactValue">{{ item.contactValue }}</span>
                                </div>
                                <div :class="item.deleteFlag == 'S' ? 'deleteFlagS' : 'deleteFlagY'">
                                    {{ item.deleteFlag == "S" ? '未公开' : '公开中' }}
                                </div>
                            </div>

                            <div style="margin-top: 15px;margin-bottom: 15px;">
                                <span class="common">{{ item.workAge }}年</span>
                                <span class="common"> | </span>
                                <span class="common">{{ item.topDegree ?
                                    item.topDegreeVlaue : '未填写' }}</span>
                                <span class="common"> | </span>
                                <span class="common">{{ item.salary }}</span>
                            </div>
                            <div>
                                <img class="w-22 h-20 mr-10" src="@/assets/Community/jobIcon.png" alt="">
                                <span class="position">{{ item.position }}</span>
                            </div>
                        </div>
                        <img src="@/assets/Community/head.png" alt="">
                    </div>
                </div>
            </div>
            <div class="addBtn" @click="toControlsResume()" v-if="select == 1 && resumelist.length < 3">新增简历</div>

            <div class="resumesubList f-cl-c" v-if="select == 2">
                <div class="resumesubItem f-cl-c" v-for="(item, index) in resumesubList" :key="index">
                    <div class="f-c-b w--100">
                        <div class="position">{{item.position}}</div>
                        <div class="salary">职位月薪：{{item.salary}}</div>
                    </div>
                    <div class="w--100 f-c mt-20">
                        <img class="eimage1" :src="item.eimage1" alt="eimage1">
                        <div class="enterpriseName">{{ item.enterpriseName }}</div>
                    </div>
                </div>
                <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size"
                :layout="layout" @pagination="fetchData" />
            </div>
        </div>
    </div>
</template>
  
<script>
  import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { CommodityTypeClass } from "@/utils/InviteJob.js"
export default {
    name: 'PersonalCenter',
    mixins: [PaginationRetention],

    data() {
        return {
            resumelist: [],
            select: 1,
            resumesubList: [],
        }
    },
    created() {
        this.forumResumeMyList();
    },
    methods: {
        // 职位招聘帖子列表接口
        forumResumeMyList() {
            let that = this;
            that.$http.forumResumeMyList({
                userId: parseInt(that.$store.state.userId)
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach((item) => {
                        item.topDegreeVlaue = CommodityTypeClass.getDegreetype(item.topDegree);
                    })
                    that.resumelist = records;
                }
            })
        },
        toresumeDetails(id){
            this.$router.push({
                    name: 'ResumeDetails',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: id
                        }))
                    }
                })
        },
        toControlsResume(){
            this.$router.push({
                    name: 'ControlsResume',
                    query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        type: 'add'
                    }))
                }
                })
        },
        getDate(){
            this.forumResumeIntoMyList(this.paging, this.pagingSize);
        },
        forumResumeIntoMyList(page = this.page, size = this.size){
            let that = this;
            that.$http.forumResumeIntoMyList({
                userId: parseInt(that.$store.state.userId),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    that.resumesubList = records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total
                }
            })
        }
    },
}

</script>
<style lang='less' scoped>
.PersonalCenter {
    .head {
        padding: 20px 0;
        border-bottom: 1px solid #F7F7F8;

        .LeftPart {
            width: 55px;
            cursor: pointer;

            .logo {
                width: 53px;
                height: 53px;
                margin-right: 18px;
            }

            .shiliaologo {
                width: 77px;
                height: 38px;
            }

        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .main {
        margin-top: 30px;

        .title {
            width: 100%;

            .select {
                color: #0363FA;
                font-size: 24px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                border-bottom: 2px solid #0363FA;
                padding-bottom: 26px;
                cursor: pointer;
            }

            .Noselect {
                font-size: 24px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                cursor: pointer;
            }
        }

        .list {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 40px;

            .resumelist {
                width: 100%;

                .resumeItem {
                    margin-bottom: 30px;
                    border-radius: 20px;
                    border: 1px solid #C4C4C4;
                    width: 700px;
                    height: 96px;
                    padding: 32px 50px;
                    cursor: pointer;

                    .infoBox {
                        .name {
                            font-size: 28px;
                            color: #333333;
                        }

                        .contactValue {
                            font-size: 20px;
                            color: #333333;
                        }

                        .deleteFlagS {
                            margin-left: 70px;
                            width: 80px;
                            height: 29px;
                            background: #FF3500;
                            opacity: 0.7;
                            border-radius: 5px;
                            color: #FFF;
                            line-height: 29px;
                            text-align: center;
                        }
                        .deleteFlagY {
                            margin-left: 70px;
                            width: 80px;
                            height: 29px;
                            background: #00BAFF;
                            opacity: 0.7;
                            border-radius: 5px;
                            color: #FFF;
                            line-height: 29px;
                            text-align: center;
                        }

                        .common {
                            color: #666666;
                            font-size: 16px;
                        }

                        .position {
                            font-size: 20px;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
            }
        }

        .addBtn {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }

        .resumesubList {
            width: 100%;
            margin-top: 50px;
            .resumesubItem {
                margin-bottom: 30px;
                border-radius: 20px;
                border: 1px solid #C4C4C4;
                width: 700px;
                height: 96px;
                padding: 32px 50px;
                cursor: pointer;
                .position{
                    font-weight: 400;
                    font-size: 24px;
                    color: #333333;
                }
                .salary{
                    font-weight: 400;
                    font-size: 16px;
                    color: #666666;
                }
                .eimage1{
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                }
                .enterpriseName{
                    margin-left: 10px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #333333;
                }
            }
        }
    }
}
</style>
  